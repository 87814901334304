<template>
  <div class="container_pages marg_top_13rem pdng_top_botton2 back_prymary">
    <div class="display_grid justify_items_center gap_8">
      <div
        class="gap_11 display_none_mobile flex_direction_column_mobile display_flex"
      >
        <div class="gap_7_3 display_flex">
          <div class="gap_2_4 height_fit_content display_grid">
            <img
              class="width_13"
              src="../../assets/ArchivosDiseñoWeb/Logos/PladconLogoClaro.png"
              alt="PladconLogoClaro"
            />
            <div class="gap_1_3 display_grid">
              <span class="paragraph color_white">{{
                $t("CallUsOrWriteToUs")
              }}</span>
              <span class="paragraph color_white text_weight_bold"
                >+57 316 747 40 04</span
              >
              <span class="paragraph color_white text_weight_bold"
                >+569 44070611</span
              >
            </div>
          </div>

          <div class="section_line">
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div class="gap_4_5 display_grid">
            <p class="paragraph color_white text_weight_bold">
              {{ $t("Worksectors") }}
            </p>

            <div class="display_grid gap_2_4">
              <span class="paragraph color_white">{{ $t("Service") }}</span>
              <span class="paragraph color_white">{{
                $t("Landfreighttransportation")
              }}</span>
              <span class="paragraph color_white">{{ $t("Health") }}</span>
              <span class="paragraph color_white">{{ $t("Government") }}</span>
              <span class="paragraph color_white">{{ $t("RealEstate") }}</span>
              <span class="paragraph color_white">{{ $t("HeavyMining") }}</span>
              <span class="paragraph color_white">{{ $t("Advertising") }}</span>
            </div>
          </div>
        </div>

        <div class="gap_4_5 height_fit_content display_grid">
          <p class="paragraph color_white text_weight_bold">
            {{ $t("Location") }}
          </p>
          <div class="display_grid">
            <span class="paragraph color_white">Barranquilla, CO</span>
            <span class="paragraph color_white">Cra 40b No. 70 - 131</span>
          </div>
        </div>

        <div class="display_grid justify_content_space_between">
          <div class="gap_4_5 height_fit_content display_grid">
            <p class="paragraph color_white text_weight_bold">
              {{ $t("ContactUs") }}
            </p>

            <div class="gap_4_5 display_grid">
              <span class="paragraph color_white">contacto@pladcon.com</span>

              <div class="gap_4_5 align_items_center display_flex">
                <!-- <a class="icon-FaceBook hover font_size_2_5"></a>
                <a class="icon-Instagram hover font_size_2_5"></a> -->
                <a
                  href="https://www.linkedin.com/company/pladcon/"
                  target="_blank"
                  class="icon-LinkedIn text_decoration_none color_white hover font_size_2_5"
                ></a>
              </div>
            </div>
          </div>

          <button
            @click="topMax"
            class="icon-top height_fit_content back button"
          ></button>
        </div>
      </div>

      <div class="display_none_desk">
        <div class="display_grid place_content_center">
          <img
            class="logo_pladcon"
            src="../../assets/ArchivosDiseñoWeb/Logos/PladconLogoClaro.png"
            alt="PladconLogoClaro"
          />
        </div>
        <div class="conatinerProyect display_grid">
          <div class="paragraph gap_2 display_grid color_white">
            <span class="text_weight_bold">{{ $t("Worksectors") }}</span>
            <span>{{ $t("Service") }}</span>
            <span>{{ $t("Landfreighttransportation") }}</span>
            <span>{{ $t("Health") }}</span>
            <span>{{ $t("Government") }}</span>
            <span>{{ $t("RealEstate") }}</span>
            <span>{{ $t("HeavyMining") }}</span>
            <span>{{ $t("Advertising") }}</span>
          </div>

          <div class="paragraph gap_2 display_grid color_white">
            <span class="text_weight_bold">{{ $t("CallUsOrWriteToUs") }}</span>
            <span>+57 316 747 40 04</span>
            <span>+569 44070611</span>
            <div class="display_grid">
              <span class="paragraph color_white">Barranquilla, CO</span>
              <span class="paragraph color_white">Cra 40b No. 70 - 131</span>
            </div>
            <span class="text_weight_bold">{{ $t("ContactUs") }}</span>
            <span>contacto@pladcon.com</span>
            <div class="gap_4_5 align_items_center display_flex">
              <!-- <a class="icon-FaceBook hover font_size_2_5"></a>
              <a class="icon-Instagram hover font_size_2_5"></a> -->
              <a
                href="https://www.linkedin.com/company/pladcon/"
                target="_blank"
                class="icon-LinkedIn color_white text_decoration_none hover font_size_2_5"
              ></a>
            </div>
          </div>
        </div>
      </div>

      <button
        @click="topMax"
        class="icon-top display_none_desk height_fit_content back button"
      ></button>

      <div class="text_align_center_mobile">
        <span class="paragraph color_white">2023 </span>
        <span class="paragraph color_blue mrg_5_right"
          >{{ $t("Allrightsreserved") }}
        </span>
        <span class="paragraph color_white">
          {{ $t("Allrightsreserved2") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { topMax } from "@/utilities";

export default {
  setup() {
    return {
      topMax,
    };
  },
};
</script>

<style scoped>
.hover:hover {
  color: var(--blue);
}

.button:hover {
  color: var(--primary);
}

.button {
  margin-top: auto;
  max-width: 11.4911rem !important;
  min-width: auto;
  height: 4.1204rem;
  color: var(--primary);
}

.mrg_5_right {
  margin-right: 0.5rem;
}

.section_line {
  height: 100%;
  display: flex;
  gap: 2.6rem;
  flex-direction: column;
}
.section_line span {
  width: 0.1rem;
  display: block;
  background: var(--white);
}
.section_line span:nth-child(1) {
  height: 71%;
}
.section_line span:nth-child(2) {
  height: 20%;
}
.section_line span:nth-child(3) {
  height: 12%;
  background: var(--blue);
}
.conatinerProyect {
  margin-top: 0;
  row-gap: 2.3rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 3.5rem;
  column-gap: 3.8rem;
}

.gap_8 {
    gap: 8rem !important;
}

.conatinerProyect div {
  height: fit-content;
}
.logo_pladcon {
  width: 14rem;
}
@media (max-width: 1250px) {
  .button {
    max-width: 100% !important;
    min-width: 100%;
    font-size: 2rem;
  }

  .container_pages>div {
    gap: 6.5rem !important;
}

.conatinerProyect {
  margin-top: 6.5rem;
}
}
</style>