<template>
  <div class="modal">
    <div class="position_relative">
      <span
        @click="close()"
        class="icon-close display_grid cursor_pointer place_items_center"
      ></span>
      <img :src="img" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["actionClose"],

  data() {
    return {
      img: require("../../assets/ArchivosDiseñoWeb/Imágenes/Perfecto.png"),
    };
  },

  methods: {
    close() {
      this.$emit("actionClose");
    },
  },

  watch: {
    "$i18n.locale": {
      handler(val) {
        if (val === "es") {
          this.img = require("../../assets/ArchivosDiseñoWeb/Imágenes/Perfecto.png");
        } else {
          this.img = require("../../assets/ArchivosDiseñoWeb/Imágenes/Perfect.png");
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.modal > div {
  background: none;
  border: none;
  width: auto;
}

img {
  width: 45.4746rem;
}

.modal > div > span {
  top: 7rem;
  right: 11rem;
}

@media (max-width: 1250px) {
  img {
    width: 34.8rem;
    height: 35.403rem;
  }
}
</style>

